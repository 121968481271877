import { mapGetters, mapActions } from "vuex"
import { getHeadersForTable } from "@/utils/table"
import {
  TABLE_NAMES,
  ISSUE_TYPES,
  FIELD_TYPES,
  ALLOWED_TYPES_FOR_ISSUE_FIELDS
} from "@/constants"

export default {
  name: "IssueFields",
  data() {
    return {
      showAddFieldsToIssueDialog: false,
      fieldsToBeAdded           : [],
      fieldsAreDraggable        : false,
      draggingIssueField        : undefined,
      dragEnterIssueField       : undefined
    }
  },
  methods: {
    ...mapActions({
      addIssueFields  : "issueFields/addIssueFields",
      notify          : "shared/notify",
      updateIssueField: "issueFields/updateIssueField"
    }),
    getFieldTypeAndIcon(field) {
      const fieldType = Object.values(FIELD_TYPES).find(type => type.value === field.type)
      return {
        icon: fieldType.icon,
        type: this.$t(fieldType.name, {
          name: this.optionListsMap[field.optionListId] ? `: ${this.optionListsMap[field.optionListId].name}` : ""
        })
      }
    },
    cancelAddFieldsToIssueHandler() {
      this.showAddFieldsToIssueDialog = false
    },
    addFieldsToIssueHandler() {
      this.addIssueFields(this.fieldsToBeAdded.map(field => ({
        fieldId    : field.id,
        issueTypeId: ISSUE_TYPES[0].id
      })))
    },
    getClassForFieldRow(item) {
      let classForFieldRow = ""
      if (item.issueFieldId === this.selectedIssueField?.id) {
        classForFieldRow += "blue lighten-5"
      }
      if (item.issueFieldId === this.dragEnterIssueField?.issueFieldId &&
          item.issueFieldId !== this.draggingIssueField?.issueFieldId) {
        classForFieldRow += "drop-row"
      }
      return classForFieldRow
    },
    handleClickOnRow(item) {
      const isCurrentRouteIssueFields    = this.$route.name === "issue-fields"
      const isDifferentIssueFieldClicked = this.$route.name === "issue-field" && this.selectedIssueField.id !== item.issueFieldId
      if (isCurrentRouteIssueFields || isDifferentIssueFieldClicked) {
        this.$router.push({
          name  : "issue-field",
          params: {
            id: item.issueFieldId
          }
        })
      }
    },
    onFieldDragStart(issueField) {
      this.draggingIssueField  = issueField
      this.dragEnterIssueField = this.draggingIssueField
    },
    onFieldDragEnter(issueField) {
      this.dragEnterIssueField = issueField
    },
    onFieldDragOver(event) {
      event.preventDefault()
    },
    onFieldDragEnd() {
      if (this.draggingIssueField.issueFieldId !== this.dragEnterIssueField.issueFieldId) {
        const isIssueFieldDraggedUp =
          !!(this.draggingIssueField.sortingOrder - this.dragEnterIssueField.sortingOrder)

        const sortingOrder = isIssueFieldDraggedUp ?
          this.dragEnterIssueField.sortingOrder : this.dragEnterIssueField.sortingOrder - 1

        this.updateIssueField({
          id: this.draggingIssueField.issueFieldId,
          sortingOrder
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      fieldsV2              : "fields/fieldsV2",
      optionLists           : "optionLists/optionLists",
      issueFields           : "issueFields/issueFields",
      isAddingIssueFields   : "issueFields/isAddingIssueFields",
      isIssueFieldsAdded    : "issueFields/isIssueFieldsAdded",
      isLoadingIssueFields  : "issueFields/isLoadingIssueFields",
      isLoadingFields       : "fields/isLoadingFieldsV2",
      isLoadingOptionLists  : "optionLists/isLoadingOptionLists",
      isUpdatingSortingOrder: "issueFields/isUpdatingSortingOrder",
      isSortingOrderUpdated : "issueFields/isSortingOrderUpdated"

    }),
    optionListsMap() {
      const optionListsMap = new Object()
      for (const optionList of this.optionLists) {
        optionListsMap[optionList.id] = optionList
      }
      return optionListsMap
    },
    calculateHeightForAddFieldToIssueTable() {
      const dataRowHeight      = 48
      const maxAvailableHeight = window.innerHeight - 430
      const maxDataRows        = Math.floor((maxAvailableHeight / dataRowHeight) - 1)
      let heightOfTable        = dataRowHeight // initialize with height for header

      if (this.itemsForAddFieldsToIssueTable.length > maxDataRows) {
        heightOfTable += maxDataRows * dataRowHeight
      } else if (!this.itemsForAddFieldsToIssueTable.length) {
        heightOfTable += dataRowHeight // one row for "no data available"
      } else {
        heightOfTable += this.itemsForAddFieldsToIssueTable.length * dataRowHeight
      }

      return heightOfTable
    },
    fieldsMap() {
      const fieldsMap = new Object()
      for (const field of this.fieldsV2) {
        fieldsMap[field.id] = field
      }
      return fieldsMap
    },
    isIssueFieldsTableDataLoading() {
      return this.isLoadingIssueFields ||
        this.isLoadingFields ||
        this.isLoadingOptionLists ||
        this.isUpdatingSortingOrder
    },
    issueFieldIds() {
      return this.issueFields.map(issueField => issueField.fieldId)
    },
    headersForIssueFieldsTable() {
      return getHeadersForTable(TABLE_NAMES.ISSUE_FIELDS, this.$t.bind(this))
    },
    headersForAddFieldsToIssueTable() {
      return getHeadersForTable(TABLE_NAMES.ISSUE_ADD_CUSTOM_FIELDS, this.$t.bind(this))
    },
    itemsForIssueFieldsTable() {
      const itemsForIssueFieldsTable = []
      const sortedIssueFields        = [...this.issueFields].sort((firstIssueField, secondIssueField) =>
        firstIssueField.sortingOrder - secondIssueField.sortingOrder
      )
      for (const issueField of sortedIssueFields) {
        const field = this.fieldsMap[issueField.fieldId]
        if (field) {
          itemsForIssueFieldsTable.push({
            id          : issueField.fieldId,
            issueFieldId: issueField.id,
            systemName  : field.systemName,
            sortingOrder: issueField.sortingOrder,
            ...this.getFieldTypeAndIcon(field)
          })
        }
      }
      return itemsForIssueFieldsTable
    },
    selectedIssueField() {
      if (this.$route.params.id) {
        return this.issueFields.find(
          issueField => issueField.id === +this.$route.params.id
        )
      }
    },
    itemsForAddFieldsToIssueTable() {
      return this.fieldsV2.filter(field =>
        !this.issueFieldIds.includes(field.id) &&
        ALLOWED_TYPES_FOR_ISSUE_FIELDS.includes(field.type)
      ).map(field => {
        return {
          id        : field.id,
          systemName: field.systemName,
          ...this.getFieldTypeAndIcon(field)
        }
      })
    }
  },
  watch: {
    isIssueFieldsAdded: {
      handler: function(value) {
        if (value) {
          this.notify({
            type: "success",
            text: "997"
          })
          this.showAddFieldsToIssueDialog = false
        }
      }
    },
    showAddFieldsToIssueDialog: {
      handler: function(value) {
        if (!value) {
          this.fieldsToBeAdded = []
        }
      }
    },
    isSortingOrderUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.notify({
            type      : "success",
            text      : "315",
            parameters: {
              fieldSystemName: this.draggingIssueField.systemName
            }
          })
          this.draggingIssueField  = undefined
          this.dragEnterIssueField = undefined
        }
      }
    }
  }
}